'use client'

import { LevelType } from '@components/ui/UIText/UITextRoot'
import { AspectRatioProps, Box, BoxProps as MuiBoxProps } from '@mui/joy'
import { pxToRem } from '@ui/style/muiTheme'
import { BulletName } from '@utils/getBulletColor'
import { ParsedUrlQueryInput } from 'querystring'
import React, { MouseEventHandler, ReactNode, useRef } from 'react'
import ContentCardBox from './ContentCardBox'
import ContentInfo from './ContentInfo'
import Thumbnail from './Thumbnail'
import { usePathname } from 'next/navigation'
import { VodInfo } from '@schemas/non-auth'

export interface ContentProps {
	articleIdx?: string
	href?: string
	query?: ParsedUrlQueryInput
	src?: string
	alt?: string
	duration?: string
	title: string
	bullet?: BulletName
	bulletColor?: 'red' | 'blue' | string
	body?: string
	additionalText?: React.ReactNode
	showVideoViewIcon?: boolean
	showLiveBadge?: boolean
	liveTagTypeCode?: string
	showBookmark?: boolean
	isBookmarked?: boolean
	onClick?: MouseEventHandler<HTMLAnchorElement>
	vodInfo?: VodInfo
}

export interface LayoutConfigProps {
	flexDirection?: MuiBoxProps['flexDirection']
	layoutGap?: MuiBoxProps['gap']
	linkSepareted?: boolean
	layoutSx?: MuiBoxProps['sx']
}
export interface ThumbnailConfigProps {
	thumbWidth?: MuiBoxProps['width']
	thumbHeight?: MuiBoxProps['height']
	badgeInset?: MuiBoxProps['left']
	thumbSx?: MuiBoxProps['sx']
	thumbRatio?: AspectRatioProps['ratio']
	radius?: MuiBoxProps['borderRadius']
	showThumb?: boolean
	isContain?: boolean
}
export interface InfoConfigProps {
	infoGap?: MuiBoxProps['gap']
	titleLevel?: LevelType
	bodyLevel?: LevelType
	isDark?: boolean
	infoSx?: MuiBoxProps['sx']
	titleClamp?: number
	bodyClamp?: number
	showBody?: boolean
	showAdditionalText?: boolean
	liveTagTypeCode?: string
}

export interface ContentCardProps {
	content?: ContentProps
	layoutConfig?: LayoutConfigProps
	thumbnailConfig?: ThumbnailConfigProps
	infoConfig?: InfoConfigProps
	wrapperProps?: MuiBoxProps
}

/**
 * ContentCard 컴포넌트
 * 프로젝트 내 대부분의 리스트/카드 구성에 사용되는 컴포넌트입니다.
 * 대부분의 가변사항을 props를 통해 지정 할 수 있습니다.
 * @param {Object} [props.content={}] - 컨텐츠 관련 props 객체
 * @param {string} [props.content.href=''] - 전체 컴포넌트를 감싸는 UILink의 href입니다.
 * @param {string} [props.content.query={}] - 전체 컴포넌트를 감싸는 UILink의 query입니다.
 * @param {string} [props.content.src] - 썸네일 이미지의 주소
 * @param {string} [props.content.alt] - 썸네일 이미지의 alt
 * @param {string} [props.content.duration] - 썸네일 우하단의 재생 시간. 입력 시에만 노출됩니다.
 * @param {string} [props.content.title=''] - 타이틀의 텍스트
 * @param {string} [props.content.bullet] - 타이틀 앞에 표시되는 단어
 * @param {'red' | 'blue'} [props.content.bulletColor] - bullet의 색상. 현재 red/blue 중 선택.(default blue)
 * @param {string} [props.content.body] - 본문의 텍스트
 * @param {React.ReactNode} [props.content.additionalText] - 작성시간, 작성자 등의 추가 정보. ReactNode로 입력 가능
 * @param {boolean} [props.content.showVideoViewIcon] - 썸네일 우상단의 video view icon 노출여부
 * @param {boolean} [props.content.showLiveBadge] - 썸네일 내 live 뱃지 노출여부
 * @param {boolean} [props.content.showBookmark] - 북마크 아이콘 노출여부
 * @param {boolean} [props.content.isBookmarked] - 북마크 상태 여부
 * @param {Object} [props.layoutConfig={}] - 레이아웃 관련 props 객체
 * @param {MuiBoxProps['flexDirection']} [props.layoutConfig.flexDirection='column'] - 썸네일과 정보 영역의 배치를 flexDirection 속성으로 조정합니다.
 * @param {MuiBoxProps['gap']} [props.layoutConfig.layoutGap=12px] - 썸네일과 정보 영역의 gap을 조정합니다.
 * @param {boolean} [props.layoutConfig.linkSepareted] - 썸네일과 정보 영역의 링크 분리 여부
 * @param {MuiBoxProps['sx']} [props.layoutConfig.layoutSx] - 레이아웃 영역의 sx 속성
 * @param {Object} [props.thumbnailConfig={}] - 썸네일 관련 props 객체
 * @param {MuiBoxProps['width']} [props.thumbnailConfig.thumbWidth] - flexDiretion을 row, row-reverse 시 필수적인 썸네일의 너비 지정
 * @param {MuiBoxProps['left']} [props.thumbnailConfig.badgeInset] - 썸네일 내 표시되는 뱃지 및 아이콘의 inset 값 (default mobile 6px, desktop 8px)
 * @param {MuiBoxProps['sx']} [props.thumbnailConfig.thumbSx] - 썸네일 영역의 sx 속성
 * @param {AspectRatioProps['ratio']} [props.thumbnailConfig.thumbRatio] - 썸네일의 가로세로 비율
 * @param {AspectRatioProps['borderRadius']} [props.thumbnailConfig.radius] - 썸네일의 border-radius 값
 * @param {boolean} [props.thumbnailConfig.showThumb=true] - 썸네일 노출 여부 showThumb가 true이고 src가 있을 때 썸네일을 렌더링 합니다.
 * @param {Object} [props.infoConfig={}] - 정보 영역 관련 props 객체
 * @param {MuiBoxProps['gap']} [props.infoConfig.infoGap] - 정보 영역 내 요소들의 gap (default 8px)
 * @param {LevelType} [props.infoConfig.titleLevel] - 타이틀의 typo level (default HeadingL)
 * @param {LevelType} [props.infoConfig.bodyLevel] - 본문의 typo level (default Footnote)
 * @param {boolean} [props.infoConfig.isDark] - 어두운 배경 위에서 사용, 텍스트 색상 변경(colBlack -> colWhite)
 * @param {MuiBoxProps['sx']} [props.infoConfig.infoSx] - 정보 영역의 sx 속성
 * @param {number} [props.infoConfig.titleClamp] - 타이틀의 말줄임 줄 수 (default 2)
 * @param {number} [props.infoConfig.bodyClamp] - 본문의 말줄임 줄 수 (default 4)
 * @param {boolean} [props.infoConfig.showBody=true] - 본문 노출 여부
 * @param {boolean} [props.infoConfig.showAdditionalText=true] - 추가 정보 노출 여부
 */
const ContentCard = React.forwardRef<HTMLDivElement, ContentCardProps>(
	(
		{
			content = {
				href: '',
				title: '',
			},
			layoutConfig = {},
			thumbnailConfig = {},
			infoConfig = {},
		},
		ref,
	) => {
		const {
			articleIdx,
			href,
			query,
			src,
			alt,
			duration,
			title,
			bullet,
			bulletColor,
			body,
			additionalText,
			showVideoViewIcon,
			showLiveBadge,
			liveTagTypeCode,
			showBookmark,
			isBookmarked,
			onClick,
			vodInfo,
		} = content
		const {
			flexDirection = 'column',
			layoutGap = pxToRem(12),
			layoutSx,
			linkSepareted,
		} = layoutConfig
		const {
			thumbWidth,
			thumbHeight,
			badgeInset,
			thumbSx,
			thumbRatio = '16/9',
			radius,
			showThumb = true,
			isContain = true,
		} = thumbnailConfig
		const {
			infoGap,
			titleLevel,
			bodyLevel,
			isDark,
			infoSx,
			titleClamp,
			bodyClamp,
			showBody = true,
			showAdditionalText = true,
		} = infoConfig
		const additionText = useRef<ReactNode>(additionalText)
		const isVideoView = !!(href && (href.includes('/video') || href.includes('/shorts')))
		const pathname = usePathname()
		const isIndexPage = pathname === '/'
		const isShorts = href?.includes('/shorts') || vodInfo?.videoType?.code === 'SHORT_FORM'
		return (
			<Box ref={ref}>
				<ContentCardBox
					href={href}
					query={query}
					flexDirection={flexDirection}
					layoutGap={layoutGap}
					layoutSx={layoutSx}
					linkSepareted={linkSepareted}
					isDark={isDark}
					onClick={onClick}
				>
					{showThumb && src && (
						<Thumbnail
							src={src}
							alt={alt}
							thumbWidth={thumbWidth}
							thumbHeight={thumbHeight}
							showLiveBadge={showLiveBadge}
							liveTagTypeCode={liveTagTypeCode}
							showVideoViewIcon={showVideoViewIcon ?? isVideoView}
							duration={duration}
							badgeInset={badgeInset}
							thumbSx={{
								position: 'relative',
								'&::before': showLiveBadge
									? {
											content: '""',
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											height: '100%',
											backgroundColor: 'rgba(0, 0, 0, 0.05)',
											zIndex: 1,
									  }
									: {},
								...thumbSx,
							}}
							thumbRatio={thumbRatio}
							radius={radius}
							isContain={isIndexPage || isVideoView ? isShorts : isContain}
						/>
					)}
					<ContentInfo
						articleIdx={articleIdx}
						infoGap={infoGap}
						titleLevel={titleLevel}
						bodyLevel={bodyLevel}
						title={title}
						titleClamp={titleClamp}
						bullet={bullet}
						bulletColor={bulletColor}
						body={body}
						bodyClamp={bodyClamp}
						additionalText={additionalText ? additionText.current : undefined}
						isDark={isDark}
						infoSx={infoSx}
						showBody={showBody}
						showAdditionalText={showAdditionalText}
						showBookmark={showBookmark}
						isBookmarked={isBookmarked}
						{...(linkSepareted && { href, query })}
					/>
				</ContentCardBox>
			</Box>
		)
	},
)

export default ContentCard
